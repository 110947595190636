<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="none" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            name="search"
            type="year"
            default="today"
            label="교육년도"
            v-model="searchParam.educationYear"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="EDU_KIND_FST_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="교육종류1"
            v-model="searchParam.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="EDU_KIND_SEC_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="교육종류2"
            v-model="searchParam.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="EDU_CLASS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="educationTypeCd"
            label="교육구분"
            v-model="searchParam.educationTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="연간 교육 추이 분석 목록"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :filtering="false"
      :checkClickFlag="false"
      :isExcelDown="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <!-- <template slot="suffixTitle">
        <font v-if="yearEdu.sysApprovalRequestId" class="text-negative" style="font-size:0.8em;font-weight:300;">
          {{searchYear}}년 {{yearEdu.plantName}} 연간교육계획은 현재 <b>{{yearEdu.approvalStatusName}}</b> 상태입니다.
        </font>
      </template> -->
      <template v-slot:customArea="{ props, col }">
        <q-btn
          class="tableinnerBtn"
          flat
          align="center"
          color="blue-6"
          label=""
          @click.stop="linkClick(props.row, col)">
          <template v-slot:default >
            <span v-html="trendMonth(props, col)" />
          </template>
        </q-btn>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'annual-education-trend-analysis',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'eduCourseId' },
          { index: 1, colName: 'eduCourseId' },
        ],
        columns: [],
        data: [],
      },
      searchParam: {
        plantCd: null,
        educationYear: '2022',
        educationKindCdSmall: null,
        educationKindCdLarge: null,
        educationTypeCd: null,
        legalEduYn: null,
      },
      yearEdu: {
        eduEducationYearPlanId: '',  // 연간교육 일련번호
        plantCd: '',  // 사업장 코드
        plantName: '',  // 사업장
        educationYear: '',  // 교육년도
        sysApprovalRequestId: '',  // 결재요청번호
        approvalStatusCd: '', // 결재관련 결재상태
        approvalStatusName: '',
      },
      searchYear: '',
      editable: true,
      listUrl: '',
      yearEduDetailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.edu.annual.trend.list.url;
      this.yearEduDetailUrl = selectConfig.sop.edu.yearEdu.get.url;
      // list setting
      let yearmonth = []
      for(let i = 1; i <= 12; i++) {
        let month = this.$_.padStart(String(i), 2, '0');
        yearmonth.push({
          name: 'monTrend' + month,
          field: 'monTrend' + month,
          label: i + '월', 
          align: 'center',
          sortable: false,
          style: 'width:65px', 
          type: "custom",
        })
      }
      const yearLabel = `${this.$language('연간교육일정')} <font class="text-negative" style="font-size:0.9em;font-weight:500;">`
        + `(✅ ${this.$language('연간교육일정에서의 변동사항이 표시됩니다.')})</font>`;
      this.grid.columns = [
          {
          name: "plantName",
          field: "plantName",
          label: "사업장",
          style: 'width:100px',
          align: "center",
          sortable: true,
        },
        {
          name: "educationCourseName",
          field: "educationCourseName",
          label: "교육과정",
          align: "left",
          style: 'width:200px',
          sortable: true,
          type: "link",
        },
        {
          name: "educationKindCdLargeName",
          field: "educationKindCdLargeName",
          label: "교육종류1",
          style: 'width:100px',
          align: "center",
          sortable: true,
        },
        {
          name: "educationKindCdSmallName",
          field: "educationKindCdSmallName",
          label: "교육종류2",
          style: 'width:100px',
          align: "center",
          sortable: true,
        },
        {
          name: "educationTypeName",
          field: "educationTypeName",
          label: "교육구분",
          style: 'width:100px',
          align: "center",
          sortable: true,
        },
        {
          label: yearLabel,
          align: 'center',
          sortable: false,
          child: yearmonth
        },
        {
          name: "relatedLawsName",
          field: "relatedLawsName",
          label: "관련법규",
          style: 'width:200px',
          align: "left",
          sortable: true,
        },
        {
          name: "departmentDeptName",
          field: "departmentDeptName",
          label: "주관부서",
          style: 'width:100px',
          align: "center",
          sortable: true,
        },
      ]
      // this.searchParam.educationYear = this.$comm.getThisYear();
      this.getList();
    },
    getList() {
      if (!this.searchParam.educationYear) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '교육년도를 선택하세요.', 
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      // 전체조회
      this.getYearEdu();
      this.searchYear = this.$_.clone(this.searchParam.educationYear);
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getYearEdu() {
      // 전체조회
      this.$http.url = this.$format(this.yearEduDetailUrl, this.searchParam.plantCd, this.searchParam.educationYear);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.yearEdu = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    trendMonth(props, col) {
      const plan = 'monplan';
      const result = 'monresult';
      let returnText = ``;
      let month = col.name.slice(-2);

      if (props.row[plan + month] === props.row[result + month]) {
        returnText = props.row[plan + month];
      } else {
        returnText = `<font class="text-negative" style="font-weight:800;">
          ${props.row[plan + month]} → ${props.row[result + month]}
          </font>`;
      }
      return returnText;
    },
    linkClick(result, col) {
      let title = '';
      let param = null;

      if (col.name === 'educationCourseName') {
        this.popupOptions.target = () =>
          import(`${"@/pages/sop/edu/cc/educationCourseDetail.vue"}`);
          param = {
            eduCourseId: result.eduCourseId ? result.eduCourseId : '',
          };
        this.popupOptions.isFull = true;
          title = '교육과정 마스터 상세'; 
      } else {
        this.popupOptions.target = () =>
          import(`${"./annualEducationTrendAnalysisDetail.vue"}`);
          param = {
            eduEducationId: result.eduEducationId ? result.eduEducationId : '',
            eduCourseId: result.eduCourseId ? result.eduCourseId : '',
            educationMonth: col.name.substring(col.name.length - 2),
            educationYear: result.educationYear,
            plantCd: result.plantCd,
            educationCourseName: result.educationCourseName,
            educationKindCdLargeName: result.educationKindCdLargeName,
            educationKindCdSmallName: result.educationKindCdSmallName,
          };
        this.popupOptions.isFull = false;
        title = this.$language('연간교육계획 상세') + ' [' + this.$language(col.label) + ']'
      }
      this.popupOptions.title = title;
      this.popupOptions.param = param;
      this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.$refs['table'].selected = []
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>